<template>
  <div class="hero">
    <div class="row">
      <div class="col col--info">
        <h3 class="subtitle">IT архитектор</h3>
        <h1 class="title">
          Цифровой аватар предскажет
          <span class="title-highlight-1">твою</span>
          <span class="title-highlight-2">зарплату в Сбере</span>
        </h1>
        <p class="description">
          Пройди игру и мы покажем, какие перспективы работы в Сбере тебя
          ожидают
        </p>
        <ButtonLogin class="mt-12 block relative z-50" />
        <div class="steps">
          <img src="/images/LandingPageData/hero-steps-panel.png" alt="lights" class="steps-panel" />
          <img src="/images/LandingPageData/hero-steps-wire.png" alt="wire" class="steps-wire" />
          <img src="/images/LandingPageData/hero-steps-light.png" alt="shine" class="steps-light" />
          <ul class="list">
            <li class="list__item" v-for="(step, idx) in steps" :key="`lights_item_${idx}`">
              <div class="list__item-group">
                <div class="list__item-sup">{{ idx + 1 }}</div>
                <img
                  src="/images/LandingPageData/lamp-off.png"
                  alt
                  class="list__item-icon list__item-icon--off"
                />
                <img
                  src="/images/LandingPageData/lamp-on.png"
                  alt
                  class="list__item-icon list__item-icon--on"
                  :class="{ 'list__item-icon--blink': idx === 2 }"
                />
              </div>
              <span class="list__item-text">{{ step }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col col--image relative z-0">
        <picture>
          <CanvasApp class="hero-house" />
          <!-- <img srcset="/images/LandingPageData/hero-house.png" alt="house" class="hero-house" /> -->
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import ButtonLogin from '../UI/ButtonLogin.vue';
import CanvasApp from '../Canvas/CanvasApp.vue';

export default {
  name: 'Hero',
  components: {
    ButtonLogin,
    CanvasApp
  },
  data() {
    return {
      steps: [
        'Пройди квиз и узнай результат',
        'Пройди интервью',
        'Получи оффер',
      ],
    };
  },

  methods: {
    blackOut() {
      const tl = anime.timeline({
        targets: '.list__item-icon--on',
        easing: 'easeOutExpo',
      });
      tl.add({
        duration: 300,
        opacity: ['0', '0.9'],
        filter: 'drop-shadow(0px 0px 10px #FFC46A)',
        delay: function (el, i) {
          return i * 500;
        },
      });
    },
    lightBlink() {
      const tl = anime.timeline({
        targets: '.list__item-icon--blink',
        loop: true,
        duration: 1000,
        direction: 'alternate',
      });
      tl.add({
        opacity: [0],
      });
      tl.add({
        opacity: [1],
      });
      tl.add({
        opacity: [0],
      });
    },
  },

  mounted() {
    setTimeout(() => {
      this.blackOut();
    }, 1500);
    setTimeout(() => {
      this.lightBlink();
    }, 3500);
  },
};
</script>

<style scoped lang="scss">
.hero {
  @apply relative;
  margin-bottom: 96px;

  .row {
    @apply flex flex-col items-center;

    @screen sm {
      @apply flex-row;
    }
  }

  .col {
    &--info {
      @apply w-full;
      max-width: 700px;
    }

    &--image {
      @apply absolute;
      right: auto;
      left: 20%;
      bottom: 194px;
      transform: translateX(-50%);
      width: 50%;

      @screen sm {
        right: -26px;
        width: auto;
        left: auto;
        top: 110px;
        bottom: auto;
        transform: none;
      }

      @screem sm {
        bottom: 435px;
      }

      @screen xl {
        top: 40px;
      }
    }
  }

  .title {
    @apply font-sbsans-text-mono font-bold mb-6;
    font-size: 34px;
    line-height: 40px;
    max-width: 550px;
    color: white;

    @screen sm {
      font-size: 52px;
      line-height: 57px;
    }

    &-highlight-1 {
      @apply inline-block;
      color: var(--sa-green);
      background: linear-gradient(45deg, #23db49, #fdfb26);
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }

    &-highlight-2 {
      @apply inline-block;
      color: var(--sa-green);
      background: linear-gradient(
        to right,
        #26b9ff 0%,
        #23db49 50%,
        #fdfb26 100%
      );
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }

  .subtitle {
    @apply font-sbsans-text-mono font-bold mb-4;
    font-size: 27px;
    line-height: 27px;
    color: var(--sa-green);
  }

  .description {
    @apply opacity-70;
    font-size: 18px;
    line-height: 23px;
    max-width: 455px;

    @screen sm {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .button-start {
    margin-top: 48px;

    @screen sm {
      margin-top: 54px;
    }
  }

  .steps {
    @apply relative z-10;
    height: auto;
    margin-top: 270px;

    @screen sm {
      max-width: none;
      height: 92px;
      margin-top: 99px;
    }

    &-panel {
      @apply absolute;
      z-index: 0;
      left: auto;
      right: 0;
      top: auto;
      bottom: -83px;
      width: 92px;
      transform: rotate(-90deg);

      @screen sm {
        left: -90px;
        top: 0;
        bottom: auto;
        right: auto;
        transform: none;
      }
    }

    &-wire {
      @apply absolute;
      top: -27px;
      left: 12px;
      width: 105px;
    }

    &-light {
      @apply absolute;
      z-index: -1;
      bottom: -215px;
      right: -110px;

      @screen tn {
        bottom: -239px;
        right: -135px;
      }

      @screen sm {
        top: -114px;
        left: -250px;
        bottom: auto;
        right: auto;
      }
    }
  }

  .list {
    @apply flex flex-col items-start h-full px-6 py-6 overflow-hidden;
    background-color: #1d1d1d;
    border-radius: 15px;

    @screen sm {
      @apply flex-row items-center px-7 py-0;
      border-radius: 0px 15px 15px 0px;
    }

    &__item {
      @apply flex items-center;

      &:not(:last-child) {
        margin-bottom: 28px;

        @screen sm {
          margin-right: 22px;
          margin-bottom: 0;
        }
      }

      &-group {
        @apply relative;
        flex-shrink: 0;
      }

      &-sup {
        @apply absolute font-sbsans-text-mono font-bold;
        font-size: 12px;
        line-height: 12px;
        top: -17px;
        color: #696969;
      }

      &-icon {
        @apply flex-shrink-0 opacity-0 transition-opacity ease-in-out;
        width: 33px;

        &--on {
          @apply opacity-0;
        }

        &--off {
          @apply absolute top-0 left-0 opacity-100;
          z-index: 0;
        }
      }
      &-text {
        @apply font-sbsans-text-mono font-bold ml-3.5;
        font-size: 18px;
        line-height: 20px;
        color: #ffeed3;

        @screen sm {
          font-size: 16px;
          line-height: 23px;
          max-width: 157px;
        }
      }
    }
  }

  .hero-house {
    max-width: 380px;
    @screen sm {
      max-width: 500px;
    }

    @screen xl {
      max-width: 100%;
    }
  }
}
</style>
