<style lang="scss" soped>
.login-btn {
  width: 249px;
}
</style>

<template>
  <a :href="authUrl" target="_blank" class="login-btn" @click="track">
    <Button class="button-start" geeckoLogo>
      <template v-slot:text>Начать игру</template>
    </Button>
  </a>
</template>

<script>
import Button from '@/components/UI/Button';

export default {
  name: '',
  components: {
    Button,
  },
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    track() {
      if (window.fbq) {
        console.log('track InitiateCheckout');
        window.fbq('track', 'InitiateCheckout')
      }
    }
  },
  computed: {
    authUrl() {
      const location = window.location.href
      return `${process.env.VUE_APP_SERVER_URL}/oauth/redirect?redirect_url=${location}`;
    },
  },
};
</script>
