<style>
canvas {
  max-width: 100%;
  height: 500px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}
@media screen and (max-width: 768px) {
  canvas {
    max-width: 300px;
    height: 300px;
  }
}
</style>
<template>
  <div class="stage"></div>
</template>

<script>
import * as PIXI from "pixi.js";
import anime from "animejs";
import { TweenMax } from "gsap";
// import ModalStartGame from '../Modals/ModalStartGame.vue';

export default {
  components: {},
  name: "CanvasApp",
  data() {
    return {
      app: null,
      container: new PIXI.Container(),

      smokeTexture: null,
      smokes: new PIXI.Container(),
      compArr: [],
      computer: null,

      loader: PIXI.Loader.shared,
      counter: 0,
      colors: ["83B799", "E2CD6D", "E86F68", "C2B28F"]
    };
  },
  mounted() {
    this.loadApp();
    this.loadImages();
  },
  methods: {
    loadApp() {
      this.app = new PIXI.Application({
        width: 150, height: 150,
        transparent: true,
        antialias: true,
      });

      this.$el.appendChild(this.app.view);
      this.app.stage.addChild(this.container)
      this.app.stage.addChild(this.smokes)
    },

    loadImages() {
      for (let i = 0; i < 7; i++) {
        let texture = PIXI.Texture.from(`/images/canvas/comp${i}.png`)
        this.compArr.push(texture)
      }
      this.smokeTexture = PIXI.Texture.from('/images/canvas/smoke.png')
      this.loader.load(this.setup)
    },

    setup() {
      this.loadComputer();
    },

    loadComputer() {
      this.computer = new PIXI.AnimatedSprite(
        this.compArr
      );
      this.computer.interactive = true;
      this.computer.buttonMode = true;
      this.computer.animationSpeed = 0.15;
      this.computer.position.set(this.app.screen.width / 2, this.app.screen.height / 2)
      this.computer.anchor.set(0.5, 0.5)
      this.container.addChild(this.computer);
      this.computer.on('pointerdown', this.onClickDown)
    },
    // showModal() {
    //   this.$modal.show(
    //     ModalStartGame,
    //     {},
    //     {
    //       name: 'start-game-modal',
    //       classes: 'settings-modal',
    //       height: 'auto',
    //       width: this.$store.state.ui.width > 768 ? '700px' : '100%',
    //       clickToClose: false,
    //       scrollable: true,
    //     },
    //     {
    //       'before-open': () => { },
    //       'before-close': () => { },
    //     },
    //   );
    // },

    shake() {
      TweenMax.fromTo(this.computer, 0.05, {
        x: this.app.screen.width / 2 - 5,
      }, {
        x: this.app.screen.width / 2 + 5,
        repeat: 5,
        yoyo: true,
        ease: 'easeInOut'
      });
    },

    pop() {
      let mouse_x = this.computer.x,
        mouse_y = this.computer.y,
        particles = [],
        spread = 100;

      for (let i = 0; i < 50; i++) {
        let particle = new PIXI.Graphics();
        let rand = anime.random(1, this.colors.length);
        particle.beginFill("0x" + this.colors[rand - 1]);
        particle.drawCircle(0, 0, 1);
        particle.endFill();
        particle.x = mouse_x;
        particle.y = mouse_y;
        particles.push(particle);
        this.container.addChild(particle);
      }

      anime({
        targets: particles,
        x() {
          return anime.random(mouse_x - spread, mouse_x + spread);
        },
        y() {
          return anime.random(mouse_y - spread, mouse_y + spread);
        },
        alpha: { delay: 300, duration: 500, value: 0 },
        easing: "easeOutQuint",
        complete: () => {
          for (let particle of particles) {
            this.app.stage.removeChild(particle);
          }
        }
      });
    },

    onClickDown() {
      this.shake()
      if (this.counter === 6) {
        this.loadSmoke();
      }
      if (++this.counter >= 6) {
        return;
      }
      this.pop()
      this.computer.gotoAndStop(this.counter)
    },

    loadSmoke() {
      const smokeParticles = []

      for (let p = 0; p < 100; p++) {
        const particle = new PIXI.Sprite(this.smokeTexture)
        particle.position.set(
          (this.app.screen.width / 2) - (Math.random() * 500 - 250),
          (this.app.screen.height / 2) - (Math.random() * 500 - 250),
        )
        particle.anchor.set(1)
        particle.rotation = Math.random() * 360
        particle.alpha = 0.5
        particle.blendMode = PIXI.BLEND_MODES.SCREEN
        particle.tint = 0xffffff
        particle._speed = (r(0, 100) - 50) / 3000
        this.smokes.scale.set(0.1)
        this.smokes.position.set(this.app.screen.width / 2, this.app.screen.height / 2)
        this.smokes.addChild(particle)
        smokeParticles.push(particle)
      }

      this.app.ticker.speed = 0.5
      this.app.ticker.add((delta) => {
        animate(delta)
      })

      const animate = (delta) => {
        let sp = smokeParticles.length
        while (sp--) {
          const x = smokeParticles[sp]._speed
          smokeParticles[sp].rotation += (delta * x)
        }
      }


      function r(min, max) {
        return Math.floor(Math.random() * (max - min) + 1) + min
      }
    }
  }
};
</script>