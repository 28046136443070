<template>
  <div class="info">
    <!-- <Link text="Вакансия" href="/" iconed /> -->
    <h2 class="title">Мы ищем IT архитекторов</h2>
    <p class="description">
      Хотим усилить команду Сбера и расширяем департамент корпоративной
      архитектуры. Приглашаем опытных корпоративных архитекторов. Начинающих
      тоже ждём: главное — стремление развиваться.
    </p>
    <section class="block">
      <h3 class="block__title">Чем занимается архитектор в Сбере</h3>
      <ul class="block__list">
        <li
          class="block__list-item"
          v-for="(item, idx) in needToDo"
          :key="`need_to_do_${idx}`"
        >
          <IconDot class="block__list-icon" />
          <span class="block__list-text">{{ item }}</span>
        </li>
      </ul>
    </section>
    <section class="block">
      <h3 class="block__title">Что ожидаем от кандидатов</h3>
      <div class="block__group">
        <div
          class="block__row"
          v-for="(row, idx) in needToKnow"
          :key="`need_to_know_${idx}`"
        >
          <div class="block__col" :class="{ 'block__col--1-2': row.col2 }">
            <div class="block__col-title">{{ row.col1.title }}</div>
            <ul class="block__list block__list--tags">
              <li
                class="block__list-item"
                v-for="(item, idx) in row.col1.langs"
                :key="`need_to_do_col_${idx}`"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div v-if="row.col2" class="block__col block__col--1-2">
            <div class="block__col-title">{{ row.col2.title }}</div>
            <ul class="block__list block__list--tags">
              <li
                class="block__list-item"
                v-for="(item, idx) in row.col2.langs"
                :key="`need_to_do_col_${idx}`"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import IconDot from '@/components/Icons/IconDot';
// import Link from '@/components/UI/Link';

export default {
  name: 'Info',
  components: {
    IconDot,
  },
  data() {
    return {
      needToDo: [
        'Лидирует в создании крутых продуктов Сбера от голосовых ассистентов до управления инвестициями',
        'Определяет целевую архитектуру продуктов и встраивает их в ИТ-ландшафт',
        'Анализирует требования от различных сторон, включая бизнес, кибербезопасность, эксплуатацию, и виртуозно находит компромиссы между ними, отражая их в архитектурных решениях',
        'Создает набор архитектурных артефактов, в том числе и архитектурные стандарты и модели для всей экосистемы Сбера',
        'Получает удовольствие от решения вместе с командами уникальных сложных технических задач',
        'Создает и мигрирует системы с использованием новейших облачных технологий и платформ на масштабе десятков миллионов пользователей',
        'Постоянно получает новые знания в Школе Архитекторов, проходя специально разработанные образовательные программы',
      ],
      needToKnow: [
        {
          col1: {
            title: 'Опыт использования технологий',
            langs: [
              'Java SE/EE',
              'Spring',
              'Kubernetes/OpenShift',
              'Istio',
              'Kafka',
              'Prometheus',
              'PostreSQL',
              'Oracle DBMS',
              'NoSQL DB',
              'Apache Ignite',
              'IBM WAS',
              'IBM MQ',
              'Hadoop',
              'Spark',
              'ML/DL-фреймворки',
              'DevOps/SRE',
            ],
          },
        },
        {
          col1: {
            title: 'Архитектурный опыт',
            langs: [
              'Паттерны архитектуры: микросервисы, монолит, serverless и др',
              'Архитектурные фреймворки и языки: TOGAF, Archimate, C4',
              'Инструменты проектирования (ARIS или аналог)',
              'Паттерны обеспечения отказоустойчовости',
              'Паттерны интеграции (REST, GraphQL, gRPC)',
              'Проектирование cloud-native приложений',
              'Проектирование highload систем',
              'Принципы построения облачных платформ',
              'Принципы управления архитектурным ландшафтом',
            ],
          },
        },
        {
          col1: {
            title: 'Дополнительные навыки и знания',
            langs: [
              'Высшее образование',
              'Лидерские качества',
              'Навыки переговоров',
              'Навыки презентации',
              'Знание методологий проектного управления (Agile, Scrum и пр)',
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.info {
  @apply border-b;
  padding-bottom: 69px;
  margin-bottom: 75px;
  border-color: rgba(white, 0.25);

  .title {
    @apply font-sbsans-text-mono font-bold mb-4 mt-3;
    font-size: 34px;
    line-height: 40px;
    max-width: 600px;
    color: var(--sa-green);
    background-image: url('/images/LandingPageData/title-pattern.png');
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-font-smoothing: antialiased;

    @screen sm {
      @apply mb-8;
      font-size: 60px;
      line-height: 60px;
    }
  }

  .description {
    @apply font-light opacity-70;
    font-size: 18px;
    line-height: 23px;
    max-width: 802px;

    @screen sm {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .block {
    @apply flex flex-col items-start;
    margin-top: 56px;

    @screen sm {
      @apply flex-row;
      margin-top: 95px;
    }

    &__title {
      @apply font-semibold mb-6;
      font-size: 24px;
      line-height: 28px;
      color: white;

      @screen sm {
        font-size: 28px;
        line-height: 33px;
        margin-right: 31px;
        margin-bottom: 0;
      }
    }

    &__row {
      @apply flex flex-col items-start justify-between w-full;

      @screen sm {
        @apply flex-row;
      }

      &:not(:last-child) {
        @apply mb-9;
      }
    }

    &__col {
      flex-basis: 100%;

      &--1-2 {
        flex-basis: calc(50% - 65px);

        &:not(:last-child) {
          @apply mb-9;

          @screen sm {
            @apply mb-0;
          }
        }
      }

      &-title {
        @apply font-semibold mb-4;
        font-size: 15px;
        line-height: 20px;
        max-width: 340px;
        color: #c4c4c4;

        @screen sm {
          font-size: 16px;
        }
      }
    }

    &__list {
      max-width: 755px;

      &--tags {
        @apply flex flex-wrap w-full -m-1;

        .block__list-item {
          @apply font-semibold m-1 px-2 py-1.5 border;
          font-size: 12px;
          border-radius: 4px;
          color: var(--sa-gray);
          border-color: rgba(white, 0.16);

          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }

      &-item {
        @apply flex items-start;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &-icon {
        --size: 15px;
        @apply mr-3 rounded-full;
        flex-shrink: 0;
        width: var(--size);
        height: var(--size);
        margin-top: 4px;
        filter: drop-shadow(0px 0px 12px rgba(28, 255, 107, 0.7));

        @screen sm {
          @apply mr-6;
        }
      }

      &-text {
        @apply opacity-70;
        font-size: 18px;
        line-height: 23px;

        @screen sm {
          font-size: 19px;
          line-height: 26px;
        }
      }
    }
  }
}
</style>
