<template>
  <div class="footer-content">
    <div class="links">
      <a href="https://geecko.ru/" target="_blank">
        <div class="geecko-logo" />
      </a>
      <!-- <a href="https://www.notion.so/geecko/Product-Camp-2021-sberquiz-geecko-ru-04a047e35b6b46158fee8385aa246e97" class="footer-link" target="_blank">Правила турнира</a> -->
      <a href="https://www.notion.so/geecko/4fa3d2f58e51434e929e39f301fa034e" class="footer-link" target="_blank">Пользовательское соглашение</a>
      <a href="https://www.notion.so/geecko/48663aa353d5482993fe59d6f3776d4b" class="footer-link" target="_blank">Политика конфиденциальности</a>
    </div>
    <div>
      <p>
        ООО «Смарт гико. 125008, Россия, г. Москва, проезд Черепановых, д.
        36, ОГРН: 1187746655359, ИНН/КПП: 7743264341/774301001
      </p>
    </div>
    <div class="footer-rights">
      © 2021 Права защищены
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterContent',
};
</script>
<style lang="scss" scoped>
.footer {
  &-content {
    position: relative;
    display: flex;
    flex-wrap: row;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 28px;
    padding-left: 144px;
    width: 100%;
    max-width: 1280px;
    .geecko-logo {
      background-image: url('/images/geecko-logo.png');
      width: 87px;
      height: 24px;
      background-size: contain;
      position: absolute;
      top: 32px;
      left: 0;
      background-repeat: no-repeat;
      &:hover {
        transform: scale(1.1);
      }
    }
    .footer-link {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      line-height: 16px;
      font-family: 'BlenderPro';
      color: #aaaaaa;
      font-weight: 500;
      margin-right: 24px;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
    .links {
      display: flex;
      flex-direction: row;
    }
    p {
      margin-top: 21px;
      font-size: 10px;
      line-height: 16px;
      font-family: 'BlenderPro Thin';
      color: #aaaaaa;
      max-width: 513px;
    }
    .footer-rights {
      position: absolute;
      top: 36px;
      right: 0;
      bottom: auto;
      font-size: 10px;
      line-height: 16px;
      text-align: right;
      color: #9fa8ad;
      font-family: 'BlenderPro';
    }
  }
}
@media screen and (max-width: 1080px) {
    .footer-rights {
      bottom: 0;
      left: 0;
    }
    .footer-link {
      flex-direction: column;
    }
    .links {
      flex-direction: column;
    }
    .footer {
      &-content {
        padding-top: 100px;
        flex-direction: column;
        padding-left: 0;
      }
    }
  }
</style>
