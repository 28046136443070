<template>
  <div>
    <div class="footer-block-ground" />
    <div class="landing-container">
      <div class="landing-block footer" style="background: #000">
        <footer-content />
      </div>
    </div>
  </div>
</template>

<script>
import FooterContent from "@/components/UI/FooterContent";

export default {
  name: "Footer",
  components: { FooterContent },
};
</script>

<style lang="scss" scoped>
.space {
  width: 100%;
  max-width: 600px;
}
.landing {
  &-block {
    margin: 0 auto;
    padding-left: 72px;
    padding-right: 72px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}
.dialog-container {
  display: flex;
  justify-content: center;
}
.footer {
  &-block {
    width: 100%;
    height: 50px;
    &-ground {
      width: 100%;
      height: 50px;
      // background: url("/images/ground-footer.png");
      background-size: 100px;
      background-repeat: repeat;
      background-position: left top;
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-wrap: row;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 28px;
    padding-left: 144px;
    width: 100%;
    max-width: 1080px;
    .geecko-logo {
      background-image: url("/images/geecko-logo.png");
      width: 87px;
      height: 24px;
      background-size: contain;
      position: absolute;
      top: 32px;
      left: 0;
      background-repeat: no-repeat;
    }
    .footer-link {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      line-height: 16px;
      font-family: "BlenderPro";
      color: #aaaaaa;
      font-weight: 500;
      margin-right: 24px;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
    .links {
      display: flex;
      flex-direction: row;
    }
    p {
      margin-top: 21px;
      font-size: 10px;
      line-height: 16px;
      font-family: "BlenderPro Thin";
      color: #aaaaaa;
      max-width: 513px;
    }
    .footer-rights {
      position: absolute;
      top: 36px;
      right: 0;
      font-size: 10px;
      line-height: 16px;
      text-align: right;
      color: #9fa8ad;
      font-family: "BlenderPro";
    }
  }
  @media screen and (max-width: 1080px) {
    .footer-rights {
      bottom: 0;
      left: 0;
    }
    .space {
      display: none;
      max-width: 0px;
      width: 0px;
    }
    .footer-link {
      flex-direction: column;
    }
    .links {
      flex-direction: column;
    }
    .footer {
      &-content {
        padding-top: 100px;
        flex-direction: column;
        padding-left: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .landing {
    &-block {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
