<template>
  <div class="landing">
    <div class="section">
      <div class="container container--type-1">
        <Header />
      </div>
    </div>
    <div class="wire wire--1">
      <img src="/images/LandingPageData/wire-1.png" alt="shine" class="wire__pic" />
    </div>
    <div class="shine shine--1">
      <img src="/images/LandingPageData/shine-1.png" alt="shine" class="pic" />
    </div>
    <section class="section">
      <div class="container container--type-2">
        <Hero />
      </div>
    </section>
    <section class="section">
      <div class="container container--type-3">
        <Info />
      </div>
    </section>
    <div class="wire wire--2">
      <img src="/images/LandingPageData/wire-2.png" alt="wire" class="wire__pic" />
    </div>
    <div class="shine shine--2">
      <img src="/images/LandingPageData/shine-2.png" alt="shine" class="pic" />
    </div>
    <section class="section">
      <div class="container container--type-3">
        <Actions />
      </div>
    </section>
    <div class="shine shine--3">
      <img src="/images/LandingPageData/shine-3.png" alt="shine" class="pic" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/UI/Header';
import Hero from '@/components/Landing/Hero';
import Info from '@/components/Landing/Info';
import Actions from '@/components/Landing/Actions';
import Footer from '@/components/UI/Footer';

export default {
  name: 'Landing',
  components: {
    Header,
    Hero,
    Info,
    Actions,
    Footer,
  },
  data() {
    return {};
  },
  computed: {
    authUrl() {
      return process.env.VUE_APP_SERVER_URL + '/api/login/geecko';
    },
  },
  async created() {},
  mounted() {
    this.$log('mounted');
  },
  beforeDestroy() {
    this.$log('beforeDestroy');
  },
};
</script>

<style lang="scss" scoped>
.landing {
  --content-width: 1280px;
  --content-gutter-x: 16px;
  --content-gutter-x-big: 16px;
  --content-gutter-x-small: 16px;
  @apply relative text-white overflow-hidden;

  @screen lg {
    --content-gutter-x: 97px;
    --content-gutter-x-big: 137px;
    --content-gutter-x-small: 32px;
  }

  .section {
    @apply w-full;
  }

  .container {
    @apply w-full mx-auto;
    max-width: var(--content-width);
    padding-right: var(--content-gutter-x);
    padding-left: var(--content-gutter-x);

    &--type-1 {
      padding-right: var(--content-gutter-x-small);
      padding-left: var(--content-gutter-x-small);
    }

    &--type-2 {
      padding-right: var(--content-gutter-x-small);
      padding-left: var(--content-gutter-x);
    }

    &--type-3 {
      padding-right: var(--content-gutter-x-big);
      padding-left: var(--content-gutter-x-big);
    }
  }

  .shine {
    @apply absolute;
    z-index: -1;

    &--1 {
      right: -18px;
      top: 332px;
    }

    &--2 {
      left: -25px;
      bottom: 200px;
    }

    &--3 {
      right: 0;
      bottom: -100px;
    }
  }

  .wire {
    @apply absolute;
    z-index: -1;

    &--1 {
      display: block;
      transform: scale(-1, 1);
      right: calc(50% + 50px);
      top: 570px;

      @screen sm {
        top: 370px;
        right: calc(50% - 872px);
        transform: scale(1);
      }

      @screen xxl {
        right: -21%;
      }

      @screen xxxl {
        right: -72px;
      }

      .wire__pic {
        width: 372px;
        @screen sm {
          width: 672px;
        }
      }
    }

    &--2 {
      display: none;

      @screen sm {
        bottom: 580px;
        left: -327px;
        display: block;
        transform: rotate(-45deg);
      }

      .wire__pic {
        width: 672px;
      }
    }
  }
}
</style>