<template>
  <div class="actions">
    <div class="row">
      <div class="col col--info">
        <h3 class="subtitle">IT архитектор</h3>
        <h2 class="title">
          Пройди игру и узнай,
          <span class="title__highlight"
            >какую зарплату ты мог бы получать в Сбере</span
          >
        </h2>
        <div class="buttons">
          <ButtonLogin class="block" />
          <!-- <Button :mode="['large', 'outline']" class="actions-button">
            <template v-slot:text>Откликнуться на вакансию</template>
          </Button> -->
        </div>
      </div>
      <div class="col col--message">
        <img src="/images/LandingPageData/main-hero.png" alt="hackerman" class="hackerman" />
        <div class="message">
          <div class="message__container">
            псс.. ты IT архитектор? Суперкомпьютер предскажет твое будущее
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLogin from '../UI/ButtonLogin.vue';

export default {
  name: 'Actions',
  components: {
    ButtonLogin
},
};
</script>

<style lang="scss" scoped>
.actions {
  .title {
    @apply font-bold mb-12;
    font-size: 34px;
    line-height: 40px;
    color: white;

    @screen sm {
      font-size: 48px;
      line-height: 52px;
      max-width: 530px;
    }

    &__highlight {
      @apply inline-block;
      color: var(--sa-green);
      background-image: url('/images/LandingPageData/title-pattern.png');
      background-repeat: repeat;
      background-size: cover;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-font-smoothing: antialiased;
    }
  }

  .subtitle {
    @apply font-sbsans-text-mono font-bold mb-4;
    font-size: 27px;
    line-height: 27px;
    color: var(--sa-green);
  }

  .row {
    @apply flex flex-col items-start justify-between;

    @screen sm {
      @apply flex-row;
    }
  }

  .col {
    @apply w-full;

    &--info {
      max-width: 630px;
    }

    &--message {
      @apply relative;
      z-index: -1;
      max-width: 285px;
      margin-top: 30px;
      margin-bottom: -108px;
      margin-right: -60px;
      margin-left: auto;

      @screen sm {
        z-index: 1;
        margin-top: -50px;
        margin-right: -80px;
        margin-bottom: 0;
      }
    }
  }

  .buttons {
    @apply flex flex-col items-center;

    @screen sm {
      @apply flex-row;
    }
  }

  .actions-button {
    &:not(:last-child) {
      @apply mb-4;

      @screen sm {
        @apply mb-0 mr-6;
      }
    }
  }

  .message {
    @apply font-joystix uppercase absolute p-4 rounded-md pb-4;
    font-size: 12px;
    line-height: 19px;
    top: 185px;
    left: -91px;
    width: 200px;
    background-color: black;

    @screen sm {
      @apply p-5;
      width: 250px;
      left: -140px;
      top: 190px;
    }

    &__container {
      @apply relative;

      &::before {
        @apply absolute;
        content: '';
        top: -28px;
        right: -32px;
        transform: rotate(-45deg);
        border: 10px solid transparent;
        border-left: 20px solid black;
        border-radius: 25px;

        @screen sm {
          top: -30px;
          right: -35px;
        }
      }
    }
  }

  .hackerman {
    width: 296px;

    @screen sm {
      width: 296px;
    }
  }
}
</style>
