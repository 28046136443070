<template>
  <button class="button" :class="className" tabindex="0">
    <IconGeeckoCircle v-if="geeckoLogo" class="button-icon" />
    <span class="button-text">
      <slot name="text" />
    </span>
  </button>
</template>

<script>
import IconGeeckoCircle from '@/components/Icons/IconGeeckoCircle';

export default {
  name: 'Button',
  components: {
    IconGeeckoCircle,
  },
  props: {
    geeckoLogo: Boolean,
    mode: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    className() {
      let mode = '';

      this.mode.forEach((item) => {
        mode += `button--${item} `;
      });

      return mode;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  --bg: var(--sa-green);
  --shadow: #20823B;
  @apply font-joystix relative flex items-center justify-center w-full transition-colors ease-in-out;
  max-width: 249px;
  height: 58px;
  background-color: var(--bg);
  filter: drop-shadow(0px 4px 0px var(--shadow));

  &::before,
  &::after {
    @apply absolute transition-colors;
    content: '';
    top: 50%;
    width: 5px;
    height: 54px;
    transform: translateY(-50%);
    background-color: var(--bg);
  }

  &::before {
    left: -5px;
  }

  &::after {
    right: -5px;
  }

  &:hover {
    --bg: #1ca743;
    background-color: var(--bg);

    & .button-icon {
      color: var(--bg);
    }
  }

  &:focus {
    --bg: #1ca743;
    background-color: var(--bg);

    & .button-icon {
      color: var(--bg);
    }
  }

  &:active {
    transform: translateY(3px);
    filter: drop-shadow(0px 0px 0px var(--shadow));
  }

  &--large {
    max-width: 100%;

    @screen sm {
      max-width: 372px;
    }
  }

  &--small {
    max-width: 100%;

    @screen sm {
      max-width: 230px;
    }
  }

  &--outline {
    --bg: #739094;
    --shadow: #3F6060;
    background-color: var(--bg);

    &:hover {
      --bg: #6e8183;
      background-color: var(--bg);
    }

    &:focus {
      --bg: #6e8183;
      background-color: var(--bg);
    }
  }

  &-icon {
    --size: 32px;
    @apply mr-3 transition-colors ease-in-out;
    width: var(--size);
    height: var(--size);
    color: var(--bg);
  }

  &-text {
    @apply font-normal;
    font-size: 18px;
    line-height: 20px;
    color: white;
  }
}
</style>
