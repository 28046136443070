<template>
  <header class="header">
    <div class="row">
      <IconSberLogo class="logo" />
      <!-- <Link text="Вакансия it architect" href="/" iconed /> -->
    </div>
  </header>
</template>

<script>
import IconSberLogo from '@/components/Icons/IconSberLogo';
// import Link from '@/components/UI/Link';

export default {
  name: 'Header',
  components: {
    IconSberLogo,
  },
};
</script>

<style lang="scss" scoped>
.header {
  @apply py-6 mb-4;

  @screen sm {
    @apply mb-16;
  }

  .row {
    @apply flex flex-col-reverse items-start justify-between;

    @screen sm {
      @apply flex-row items-center;
    }
  }

  .logo {
    width: 86px;
    height: 24px;
  }
}
</style>
